import styled from "styled-components";
import BackgroundImage from "assets/images/login/background-prime-club.jpeg";
import { BREAKPOINTS } from "styles/spacing";
import theme from "styles/theme";
import "styles/global";

export const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000000;
  overflow: hidden;
  background-color: ${theme.COLORS.BACKGROUND_PRIMARY};

  &::before {
    content: "";
    background-image: url(${BackgroundImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.8;
    z-index: -1;
  }

  @media (max-width: ${BREAKPOINTS.WIDTH.SM}) {
    &::before {
      background-size: auto 100vh;
      background-position: top center;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 5%;
  text-align: center;
  color: ${theme.COLORS.TEXT_PRIMARY};
  z-index: 1000001;

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    justify-content: end;
    align-items: start;
    text-align: start;
    padding: 0 0 10% 20%;
  }
`;

export const Title = styled.span`
  color: ${theme.COLORS.TEXT_PRIMARY};
  font-family: Roboto;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    font-size: 4rem;
    text-align: start;
  }
`;

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    justify-content: start;
    align-items: start;
  }
`;

export const Subtitle = styled.span`
  color: ${theme.COLORS.TEXT_PRIMARY};
  font-family: Roboto;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    font-size: 2rem;
    text-align: start;
  }
`;

export const Description = styled.span`
  color: ${theme.COLORS.TEXT_PRIMARY};
  font-family: Roboto;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    font-size: 1.5rem;
    text-align: start;
  }
`;

export const IconWrapper = styled.div`
  margin-bottom: 1.5rem;
  width: 60px;

  svg {
    width: 60px;
    height: 60px;
    fill: ${theme.COLORS.WARNING};
  }

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    width: 90px;

    svg {
      width: 90px;
      height: 90px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (min-width: ${BREAKPOINTS.WIDTH.MD}) {
    justify-content: start;
  }
`;
