import React, { useState } from "react";
import Header from "components/Header";
import NewsMarquee from "componentsNew/NewsMarquee";
import Text from "components/Text";
import Helper from "components/Helper";
import SideBar from "components/SideBar";
import UserProfileCard from "components/UserProfileCard";
import { useSideBar } from "hooks/useSideBar";
import { useUser } from "global/UserContext";
import { useTab } from "global/TabContext";
import { useIsMobile, useIsTablet, useIsDesktop } from "utils/mediaQueries";
import * as S from "./styles";
import {
  generateSidebarMainMenu,
  generateSidebarMenuForDevice,
} from "utils/sidebarUtils";
import MobileMenu from "components/MobileMenu";

interface ILayout {
  children: React.ReactNode | React.ReactNode[];
}

const Layout = ({ children }: ILayout) => {
  const { open } = useSideBar();
  const { setUser, token, features, permissions } = useUser();
  const { activeTabContext } = useTab();

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();

  const isVisibleCard = activeTabContext === "tab_cockpit";

  const menuItems = generateSidebarMenuForDevice(
    features,
    permissions,
    isMobile || isTablet,
  );

  const mobileMainMenu = generateSidebarMainMenu(
    features,
    permissions,
    isMobile || isTablet,
  );

  return (
    <S.Main>
      <S.Container>
        {isDesktop && (
          <>
            <SideBar />
            <Header />
            {isVisibleCard ? (
              <>
                {/* <S.NewsContainer>
            <NewsMarquee />
            </S.NewsContainer> */}

                <S.Box>
                  <S.UserCardContainer>
                    <UserProfileCard />
                  </S.UserCardContainer>

                  <S.Wrapper open={false}>
                    <S.Content>{children}</S.Content>
                  </S.Wrapper>
                </S.Box>
              </>
            ) : (
              <S.Wrapper open={true}>
                <S.Content>{children}</S.Content>
              </S.Wrapper>
            )}
            <Helper />
          </>
        )}

        {isMobile && (
          <>
            <Header />
            <MobileMenu mainMenu={mobileMainMenu} menuItems={menuItems} />
            <S.WrapperMobile open={false}>
              <S.ContentMobile>{children}</S.ContentMobile>
            </S.WrapperMobile>
          </>
        )}

        {isTablet && (
          <>
            <Header />
            <MobileMenu mainMenu={mobileMainMenu} menuItems={menuItems} />
            <S.WrapperMobile open={false}>
              <S.ContentMobile>{children}</S.ContentMobile>
            </S.WrapperMobile>
          </>
        )}
      </S.Container>
    </S.Main>
  );
};

export default Layout;
