import React from "react";
import { Routes, Route, Outlet, Navigate, useNavigate } from "react-router-dom";
import { ROUTES } from "utils/constants";
import { PublicRoute } from "./PublicRoutes";
import { PrivateRoute } from "./PrivateRoutes";
import RouteTransition from "./RouteTransition";
import Layout from "components/Layout";
import Logout from "components/Logout";
import { Login } from "pages/Login";
import { Register } from "pages/Register";
import { ForgotPassword } from "pages/ForgotPassword";
import { Cockpit } from "pages/Cockpit";
import { CommunityProfile } from "pages/CommunityProfile";
import { IntelligenceNetworking } from "pages/IntelligenceNetworking";
import { ThematicGroups } from "pages/ThematicGroups";
import { ActivationConnections } from "pages/ActivationConnections";
import { NetworkOrchestration } from "pages/NetworkOrchestration";
import { ClubProfile } from "pages/ClubProfile";
import { ClubIntelligenceConnections } from "pages/ClubIntelligenceConnections";
import { UserProfile } from "pages/UserProfile";
import { DigitalGovernance } from "pages/DigitalGovernance";
import { ErrorPage } from "pages/Errors/Error";
import UserProfileMobile from "components/Layout/components/UserProfileMobile";
import { useUser } from "global/UserContext";

export const AuthRoutes = () => {
  const { token, permissions } = useUser();
  const navigate = useNavigate();

  const hasAccess = (requiredPermission: string): boolean => {
    if (!permissions) return false;
    return permissions.includes(requiredPermission);
  };

  return (
    <Routes>
      <Route element={<RouteTransition />}>
        <Route
          path="/"
          element={
            <Navigate
              to={token ? ROUTES.ROOT + ROUTES.COCKPIT : ROUTES.AUTH.LOGIN}
              replace
            />
          }
        />

        <Route
          path={ROUTES.AUTH.LOGIN}
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path={ROUTES.AUTH.REGISTER}
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />
        <Route
          path={ROUTES.AUTH.FORGOT_PASSWORD}
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          path={ROUTES.AUTH.LOGOUT}
          element={
            <PublicRoute>
              <Logout />
            </PublicRoute>
          }
        />

        <Route
          path={ROUTES.ROOT}
          element={
            <PrivateRoute>
              <Layout>
                <Outlet />
              </Layout>
            </PrivateRoute>
          }
        >
          <Route index element={<Navigate to={ROUTES.COCKPIT} replace />} />

          <Route
            index
            path={ROUTES.COCKPIT}
            element={
              <PrivateRoute>
                <Cockpit />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CLUB_ACTIVATION_ROUNDS}
            element={
              <PrivateRoute>
                <ThematicGroups />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CLUB_PRIME_CLUB_PROFILE}
            element={
              <PrivateRoute>
                <ClubProfile />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CLUB_INTELLIGENCE_CONNECTIONS}
            element={
              <PrivateRoute>
                <ClubIntelligenceConnections />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CLUB_ACTIVATION_CONNECTIONS}
            element={
              <PrivateRoute>
                <ActivationConnections />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CLUB_NETWORKING_ORCHESTRATION}
            element={
              <PrivateRoute>
                <NetworkOrchestration />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.COMMUNITY_PROFILE}
            element={
              <PrivateRoute>
                <CommunityProfile />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.COMMUNITY_INTELLIGENCE_NETWORKING}
            element={
              <PrivateRoute>
                <IntelligenceNetworking />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.COMMUNITY_THEMATIC_GROUPS}
            element={
              <PrivateRoute>
                <ThematicGroups />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.USER.PROFILE}
            element={
              <PrivateRoute>
                <UserProfile />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.DIGITAL_GOVERNANCE}
            element={
              <PrivateRoute>
                <DigitalGovernance />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.USER.PROFILE_MOBILE}
            element={
              <PrivateRoute>
                <UserProfileMobile />
              </PrivateRoute>
            }
          />

          <Route
            path="*"
            element={
              <ErrorPage
                title="Erro!"
                error="404"
                message="Página não encontrada."
                //buttonText="Voltar para o início"
                //onButtonClick={() => navigate(ROUTES.ROOT)}
              />
            }
          />
        </Route>

        <Route
          path="*"
          element={
            <ErrorPage
              title="Erro!"
              error="404"
              message="Página não encontrada."
              //buttonText="Voltar para o início"
              //onButtonClick={() => navigate(ROUTES.ROOT)}
            />
          }
        />
      </Route>
    </Routes>
  );
};
