import React, { useState } from "react";
import {
  Drawer,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { SidebarMenuItem } from "utils/sidebarUtils";
import { useIsDesktop, useIsMobile, useIsTablet } from "utils/mediaQueries";
import { adjustMainMenu } from "utils/sidebarUtils";
import * as S from "./styles";
import theme from "styles/theme";
import { useSideBar } from "hooks/useSideBar";
import { useMenuTitle } from "hooks/useMenuTitleContext";

interface MobileMenuProps {
  mainMenu: SidebarMenuItem[];
  menuItems: SidebarMenuItem[];
}

const MobileMenu: React.FC<MobileMenuProps> = ({ mainMenu, menuItems }) => {
  const [drawerOpen, setDrawerOpen] = useState<string | null>(null);
  const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>(
    {},
  );
  const [navigationValue, setNavigationValue] = useState(0);

  const toggleDrawer = (menuType: string | null) => {
    setDrawerOpen((prevDrawer) => (prevDrawer === menuType ? null : menuType));
  };

  const { setTitleMenu } = useSideBar();
  const { updateMenuTitle } = useMenuTitle();

  const toggleSubMenu = (title: string) => {
    setExpandedItems((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();

  const adjustedMainMenu = adjustMainMenu(mainMenu);

  return (
    <>
      <BottomNavigation
        value={navigationValue}
        onChange={(event, newValue) => setNavigationValue(newValue)}
        showLabels
        sx={{ position: "fixed", bottom: 0, width: "100%", zIndex: 1300 }}
      >
        <BottomNavigationAction
          label="Menu"
          icon={<MenuIcon />}
          onClick={() => toggleDrawer("mainMenu")}
        />
        {menuItems.map((item) => (
          <BottomNavigationAction
            key={item.title}
            label={item.label}
            icon={<img src={item.icon} alt={item.title} />}
            onClick={() => toggleDrawer(item.title)}
          />
        ))}
      </BottomNavigation>

      <Drawer
        anchor={isMobile ? "top" : "left"}
        open={drawerOpen === "mainMenu"}
        onClose={() => toggleDrawer(null)}
        sx={{
          "& .MuiDrawer-paper": {
            width: isMobile ? "100%" : "300px",
            height: isTablet ? "100vh" : "75vh",
            top: "72px",
            borderRadius: "8px",
            background: theme.COLORS.BACKGROUND_SECONDARY,
          },
        }}
      >
        <S.DrawerContent isMobile={isMobile}>
          {adjustedMainMenu.map((item) => (
            <React.Fragment key={item.title}>
              <S.MenuItem
                isActive={item.isActive}
                onClick={() => {
                  if (!item.subMenu || item.subMenu.length === 0) {
                    updateMenuTitle(item.label);
                    window.location.assign(item.path);
                  } else {
                    toggleSubMenu(item.title);
                  }
                }}
              >
                <S.MenuIconLabel>
                  <img src={item.icon} alt={item.title} />
                  <span>{item.label}</span>
                </S.MenuIconLabel>
                {item.subMenu && item.subMenu.length > 0 && (
                  <>
                    {expandedItems[item.title] ? (
                      <ExpandLessIcon
                        fontSize="large"
                        sx={{ color: theme.COLORS.PRIMARY }}
                      />
                    ) : (
                      <ExpandMoreIcon
                        fontSize="large"
                        sx={{ color: theme.COLORS.PRIMARY }}
                      />
                    )}
                  </>
                )}
              </S.MenuItem>

              {item.subMenu && expandedItems[item.title] && (
                <S.SubMenuContainer>
                  {item.subMenu.map((subItem) => (
                    <S.SubMenuItem
                      key={subItem.title}
                      isActive={subItem.isActive}
                      onClick={() => {
                        updateMenuTitle(subItem.label);
                        window.location.assign(subItem.path);
                      }}
                    >
                      <span>{subItem.label}</span>
                    </S.SubMenuItem>
                  ))}
                </S.SubMenuContainer>
              )}
            </React.Fragment>
          ))}
        </S.DrawerContent>
      </Drawer>

      {menuItems.map((item) => (
        <Drawer
          key={item.title}
          anchor={isMobile ? "top" : "left"}
          open={drawerOpen === item.title}
          onClose={() => toggleDrawer(null)}
          sx={{
            "& .MuiDrawer-paper": {
              width: isMobile ? "100%" : "300px",
              height: isTablet ? "100vh" : "75vh",
              top: "72px",
              borderRadius: "8px",
              background: theme.COLORS.BACKGROUND_SECONDARY,
            },
          }}
        >
          <S.DrawerContent isMobile={isMobile}>
            <S.MenuItem
              isActive={item.isActive}
              onClick={() => {
                if (!item.subMenu || item.subMenu.length === 0) {
                  updateMenuTitle(item.label);
                  window.location.assign(item.path);
                } else {
                  toggleSubMenu(item.title);
                }
              }}
            >
              <S.MenuIconLabel>
                <img src={item.icon} alt={item.title} />
                <span>{item.label}</span>
              </S.MenuIconLabel>
              {item.subMenu && (
                <>
                  {expandedItems[item.title] ? (
                    <ExpandLessIcon
                      fontSize="large"
                      sx={{ color: theme.COLORS.PRIMARY }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      fontSize="large"
                      sx={{ color: theme.COLORS.PRIMARY }}
                    />
                  )}
                </>
              )}
            </S.MenuItem>

            {item.subMenu && expandedItems[item.title] && (
              <S.SubMenuContainer>
                {item.subMenu.map((subItem) => (
                  <S.SubMenuItem
                    key={subItem.title}
                    isActive={subItem.isActive}
                    onClick={() => {
                      updateMenuTitle(subItem.label);
                      window.location.assign(subItem.path);
                    }}
                  >
                    <span>{subItem.label}</span>
                  </S.SubMenuItem>
                ))}
              </S.SubMenuContainer>
            )}
          </S.DrawerContent>
        </Drawer>
      ))}
    </>
  );
};

export default MobileMenu;
