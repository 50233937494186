import * as React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import LogoHeader from "assets/images/logo/shield-logo-primeclub-shade.png";
import LogoHeaderMobile from "assets/images/header/logo-prime.png";
import { useSideBar } from "hooks/useSideBar";
import { useMenuTitle } from "hooks/useMenuTitleContext";
import theme from "styles/theme";
import * as S from "./styles";
import { useIsDesktop, useIsMobile, useIsTablet } from "utils/mediaQueries";

interface HeaderProps {
  activeSubmenu?: string;
}

const Header: React.FC<HeaderProps> = ({ activeSubmenu }) => {
  const location = useLocation();
  const { open, titleMenu, setTitleMenu } = useSideBar();
  const { menuTitle, updateMenuTitle } = useMenuTitle();

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();

  useEffect(() => {
    let newTitle: string | boolean = titleMenu;

    switch (true) {
      case location.pathname.includes("/dashboard/electoral-profile/wizard"):
        newTitle = "Wizard Eleitoral";
        break;
      case location.pathname.includes("/dashboard/acquisitions/signature"):
        newTitle = "Aquisições";
        break;
      case location.pathname.includes("/dashboard/settings/profile"):
        newTitle = "Configurações";
        break;
      case location.pathname.includes("/dashboard/application-management/team"):
        newTitle = "Portfólio de Advisors";
        break;
      default:
        //newTitle = "";
        break;
    }

    if (typeof newTitle === "string") {
      updateMenuTitle(newTitle);
    }
  }, [location.pathname, titleMenu, updateMenuTitle]);

  return (
    <S.HeaderContainer open={open} isMobile={isMobile || isTablet}>
      {isMobile || isTablet ? (
        <S.MobileHeader>
          <S.LogoHeaderImage
            src={LogoHeaderMobile}
            alt="Logo Prime Club"
            isMobile={true}
          />
          <S.Title>
            <S.TitleText>{menuTitle || ""}</S.TitleText>
          </S.Title>
        </S.MobileHeader>
      ) : (
        <>
          <S.Title>
            <FontAwesomeIcon
              icon={faBell}
              style={{
                fontSize: "1.2rem",
                color: theme.COLORS.WHITE_3,
                marginRight: "1.25rem",
                marginLeft: "1.25rem",
              }}
            />
            <S.TitleText>{menuTitle || ""}</S.TitleText>
          </S.Title>
          <S.LogoHeaderImage
            src={LogoHeader}
            alt="Logo Prime Club"
            isMobile={false}
          />
        </>
      )}
    </S.HeaderContainer>
  );
};

export default Header;
