import React, { useEffect } from "react";
import IntegratedFeed from "componentsNew/Cockpit/components/IntegratedFeed";
import { useTab } from "global/TabContext";
import * as S from "./styles";
import { useIsDesktop, useIsMobile, useIsTablet } from "utils/mediaQueries";
import UnderConstruction from "components/Layout/components/UnderConstruction";

export const Cockpit: React.FC = () => {
  const [activeTab] = React.useState("tab_cockpit");
  const { setActiveTabContext } = useTab();

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();

  return isMobile || isTablet ? (
    <UnderConstruction />
  ) : (
    <S.Wrapper>
      <S.Container>
        <S.Content>
          <S.Section>
            <IntegratedFeed isCandidate />
          </S.Section>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  );
};
